import React, { Component } from 'react';
import '../style/AdminHeader.css'

class AdminHeader extends Component {
    constructor(props){
        super(props)
        this.state = {}
     }
    render() {
      return (
        <div className="header">
          <div className="title">
          <h1>Companies Events</h1>
          </div>
        </div>
      );
    }
  }
  
  export default AdminHeader;