
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
const firebaseConfig = {
  apiKey: "AIzaSyDoogI7VnfL-ljJkuvTYLGF6HF8qXZw3QU",
  authDomain: "room-picker.firebaseapp.com",
  databaseURL: "https://room-picker.firebaseio.com",
  projectId: "room-picker",
  storageBucket: "room-picker.appspot.com",
  messagingSenderId: "804767551923",
  appId: "1:804767551923:web:03553ffd384953f4a1bbc6"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

  export default firebaseApp;
