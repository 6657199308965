import React, { Component } from 'react';
import AdminHeader from './AdminHeader';
import '../style/AdminPage.css'
import firebaseApp from '../firebaseConfig'
import { Table, Divider, Tag, message } from 'antd';
import { Link } from 'react-router-dom';
import {Button} from 'antd';
import Login from './Login'
import Thinking from './Thinking'
import ReactToExcel from 'react-html-table-to-excel'
import { Match } from '../models/Match';
import { generateShowHourMinuteSecond } from 'antd/lib/time-picker';
import encodeForFireBase from '../Functions/encodeForFireBase.js'
require("react/package.json"); // react is a peer dependency. 
class AdminPage extends Component {
    constructor(props){
        super(props)
        this.state = {
          events : [],
          userSignIn : 'unKnown'
        }
        this.checkUserSignIn = this.checkUserSignIn.bind(this);
        this.delete = this.delete.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.finishActivity = this.finishActivity.bind(this);
        this.prepareToExport = this.prepareToExport.bind(this);
        this.generate = this.generate.bind(this);
        this.getRoomTypeName = this.getRoomTypeName.bind(this);
     }

     componentDidMount(){
       this.getEvents();
       this.checkUserSignIn();
     }

     checkUserSignIn() {
       const myThis = this;
      firebaseApp.auth().onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in.
          myThis.setState({
            userSignIn : true
          })
        } else {
          // No user is signed in.
          myThis.setState({
            userSignIn : false
          })
        }
      });
     }
    

    generate(event){
      var workers = event.workers;

      var generateRealMatches = [];
      // var halfWorkersList = Object.values(workers).filter((worker,dataIndex) => 
      // (dataIndex % 2 == 0));
      // var halfWorkersList2 = Object.values(workers).filter((worker,dataIndex) => 
      // (dataIndex % 1 == 0));
    

      while(workers.length > 0)
      {
        var employee1 = workers[0];
        workers.splice(0,1);
        var employee2 = '';
        for(var t=0; t < workers.length; t++)
        {
          if(employee1.roomType === workers[t].roomType && employee1.id !== workers[t].id)
          {
            
            employee2 = workers[t];
            workers.splice(t,1);
            t = workers.length;
          }
        }
        if(!employee2){
          employee2 = {
            firstName : '------',
            lastName : '------',
            id : '---------',
          }
        }
        generateRealMatches.push(new Match(employee1.firstName,employee1.lastName,employee1.id,employee2.firstName,employee2.lastName,employee2.id,employee1.roomType))
        
        // workers = Object.values(workers).filter((worker,dataIndex) => 
        // (worker.id != employee2.id));
      }


      return generateRealMatches.map((match) => {
        const {firstName1 , lastName1 , id1 , firstName2 , lastName2 , id2 , roomType } = match ;
        return (
            <tr key={id1}>
              <td>{firstName1}</td>
              <td>{lastName1}</td>
              <td>{id1}</td>
              <td>{""}</td>
              <td>&nbsp;{""}</td>
              <td>{""}</td>
              <td>{firstName2}</td>
              <td>{lastName2}</td>
              <td>{id2}</td>
              <th>{""}</th>
              <th>{""}</th>
              <th>{""}</th>
              <td>{""}</td>
              <td>{this.getRoomTypeName(roomType,event.dictionary)}</td>
            </tr>)
      });   
    }

    delete (event) {
      console.log("event name : " + event.eventName);
      console.log("company name :" + event.companyName);
      firebaseApp.database().ref(encodeForFireBase(event.companyName)).child(encodeForFireBase(event.eventName)).remove()
      .then((res)  => {
        // File deleted successfully
        this.getEvents()//TODO: delete from state (that is unnessary Firebase call now)
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });
    }

    getRoomTypeName(roomTypeNumber,dictionary) {
      var roomTypeName;
      dictionary.forEach((room) => {
        if(room.Number == roomTypeNumber)
        {
          roomTypeName = room.roomType
        }
      })
      return roomTypeName
    }

    prepareToExport (event) {
      if(!!event.matches){
        const readyHtml = 
          <table id="table-to-xls">
                  <thead>
                  <tr>
                  <th>first name</th>
                  <th>last name</th>
                  <th>id</th>
                  <th>email</th>
                  <th>phone</th>
                  <th>title</th>
                  <th>first name</th>
                  <th>last name</th>
                  <th>id</th>
                  <th>email</th>
                  <th>phone</th>
                  <th>title</th>
                  <th>comment</th>
                  <th>room type</th>
                  </tr>
                  </thead>
                  <tbody>
                    { 
                      Object.values(event.matches).map((x) => 
                      <tr key={x.employee1_id}>
                        <td>{x.employee1_fname}</td>
                        <td>{x.employee1_lname}</td>
                        <td>{x.employee1_id}</td>
                        <td>{x.employee1_email}</td>
                        <td>&nbsp;{x.employee1_phone}</td>
                        <td>{x.employee1_title}</td>
                        <td>{x.employee2_fname}</td>
                        <td>{x.employee2_lname}</td>
                        <td>{x.employee2_id}</td>
                        <td>{x.employee2_email}</td>
                        <td>&nbsp;{x.employee2_phone}</td>
                        <td>{x.employee2_title}</td>
                        <td>{x.matchComment}</td>
                        <td>{this.getRoomTypeName(x.roomType,event.dictionary)}</td>
                      </tr>)
                    }
                    {
                      (!!event.workers) ? this.generate(event) : ''
                    }
                  </tbody>
              </table>

              this.setState({
                readyHtml : readyHtml,
              })
              var elements = document.getElementsByClassName("export");
              [...elements].forEach(function(element) {
                element.classList.add("hidden")
            });
              var element = document.getElementById(event.eventUid);
              element.classList.remove("hidden");
      }else{
        message.error('no roommate matches yet!')
      }
    }
    
    finishActivity (event) {
      const now = new Date();
      firebaseApp.database().ref(encodeForFireBase(event.companyName)).child(encodeForFireBase(event.eventName)).update({endPeriodTime: now.toString()})
      .then((res) => {
        // File deleted successfully
        this.getEvents()//TODO: delete from state (that is unnessary Firebase call now)
      }).catch((error)  => {
        // Uh-oh, an error occurred!
        console.log(error);
      });

    }
     
    getEvents = () => {
      var events = [];
      firebaseApp.database().ref().once("value", snapshot => {
      const val = snapshot.val();
      if(!!val) 
      {
        Object.values(val).map((company)=> {Object.values(company).map((event)=> {
   
          firebaseApp.storage().ref("Companies-logo/"+encodeForFireBase(event.companyName)).getDownloadURL().then((res) => {
              var eventFix = event;
              var today = new Date();
              var endPeriodTime = new Date(event.endPeriodTime);
              if((endPeriodTime - today) > 0)
              {
                eventFix["activity"] = ['active'];
              }
              else{
                eventFix["activity"] = ['finished'];
              }
              eventFix["imageUrl"] =  res;
              eventFix["key"] = event.eventUid;
              events.push(eventFix);
              this.setState({
                events : events    
                });
          });
          return true
        })
      return true})
      }
      });
    }
    



    render() {


      function timeDifference(current, previous) {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
    
        var elapsed = current - previous;
    
        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' seconds ago';   
        }
    
        else if (elapsed < msPerHour) {
             return Math.round(elapsed/msPerMinute) + ' minutes ago';   
        }
    
        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' hours ago';   
        }
        else {
            return new Date(previous).toLocaleString().split(",")[0]  ; 
        }
    }




      const adminTableColumns = [
        {
          title: 'Time',
          dataIndex: 'timeStamp',
          key: 'timeStamp',
          className: 'timeStamp',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.timeStamp - b.timeStamp,
          render: (time) => (
            <span>
            {timeDifference(Date.now(),time)}
            </span>
          ),
        },
        {
          title: 'Company',
          dataIndex: 'companyName',
          key: 'companyName',
          className : 'companyName',
          // defaultSortOrder: 'descend',
          // sorter: (a, b) => a.companyName.toLowerCase() - b.companyName.toLowerCase(),
        },
        {
          title: 'Event',
          dataIndex: 'eventName',
          key: 'eventName',
          className: 'eventName'
        },
        {
          title: 'Logo',
          dataIndex: 'imageUrl',
          key: 'imageUrl',
          render: imageUrl => <img src={imageUrl} alt="logo" className={"logo"} ></img>,
        },
        {
          title: 'URL',
          dataIndex: 'eventUid',
          key: 'url',
          className : 'url',
          render: eventUid => <Link to={"/" + eventUid}>{eventUid}</Link>,
        },
        {
          title: 'Activity',
          key: 'activity',
          dataIndex: 'activity',
          className: 'activity',
          render: activity => (
            <span>
              {activity.map(activity => {
                let color = activity == "active"  ? 'green' : 'volcano';
                // if (tag == 'finished') {
                //   color = 'volcano';
                // }
                return (
                  <Tag color={color} key={activity}>
                    {activity.toUpperCase()}
                  </Tag>
                );
              })}
            </span>
          ),
        },
        {
          title: 'Closing time',
          dataIndex: 'endPeriodTime',
          key: 'endPeriodTime',
          className : 'endPeriodTime',
          render: endPeriodTime => (
            <span>
              {new Date(endPeriodTime).toLocaleString().split(",")[0]}
            </span>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          className: 'action',
          render: (event) => (
            <span>
              <a onClick={() => { if (window.confirm('Are you sure you want to finish this activity?')) this.finishActivity(event)}}>Finish activity</a>
              <Divider type="vertical" />
              <a onClick={() => { if (window.confirm('Are you sure you want to delete this event?')) this.delete(event)}}>Delete</a>
            </span>
          ),
        },
        {
          title: 'Prepare',
          key: 'Prepare',
          className: 'prepare',
          render: (event) => (
            <span>
              <a onClick={() => {this.prepareToExport(event)}}>Prepare to export</a>
            </span>
          ),
        },
        {
          title: 'Export',
          key: 'export',
          className: 'exportColumn',
          render: (event) => (
          <ReactToExcel
          className = {"ant-btn ant-btn-primary export hidden"}
          table = "table-to-xls"
          id = {event.eventUid}
          filename = {event.companyName}
          sheet = {event.eventName}
          buttonText = "EXPORT"
          /> 
        ),
        }
      ];
      
      return (
        <div>
          {
          (this.state.userSignIn == 'unKnown')
          ? 
          <Thinking />
          :
            (!this.state.userSignIn)
            ?
              <div className="loginPage">
               <Login />
              </div>
            :
              <div className="adminPage">
                <AdminHeader/>
                <div className="adminBody">
                  <Table columns={adminTableColumns} dataSource={this.state.events} pagination={{ pageSize: 7 }} />
                  <br/>
                  <div className="addEventBtn">
                  <Button type="primary">
                  <Link to="/admin/add-event"><b>Create new event</b></Link>
                  </Button>
                  </div>
              </div>
                {/* <AdminBody/> */}
                {
                  <div className="hidden">
                  {this.state.readyHtml}
                  </div>
                }
              </div>
          }
        </div>
      );
    }
  }
  
  export default AdminPage;