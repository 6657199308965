import React, { Component  } from 'react';
import GoHome from './GoHome'
import Thinking from './Thinking'
import EventPage from './EventPage'
import FinishedEvent from './FinishedEvent'
import '../style/LandingPage.css'
import firebaseApp from '../firebaseConfig';
import encodeForFireBase from '../Functions/encodeForFireBase.js'
require('firebase/auth');
require('firebase/database');

class LandingPage extends Component {
    constructor(props){
        super(props)
        this.state = {
          event : '',
          status: ''
        }
        this.getEvent = this.getEvent.bind(this);
        this.click = this.click.bind(this);
        this.sendFromEventPage = this.sendFromEventPage.bind(this);
     }

     componentDidMount () {
      this.getEvent();
     }

     click () {
        debugger
     }

     sendFromEventPage (val) {
      this.getEvent()
     }

     getEvent () {
      const uid = this.props.match.params.uid;

     
        firebaseApp.database().ref().once("value", snapshot => {
    
        const x = snapshot.val();
        this.setState({
          companiesNumber : Object.keys(x).length
        })
        const myThis = this;
        Object.values(x).map((company)=> {Object.values(company).map((event)=> {

          if(event.eventUid == uid){
            myThis.setState({
              status : 'validEvent'
              });

            firebaseApp.storage().ref("Companies-logo/"+encodeForFireBase(event.companyName)).getDownloadURL().then((res) => {
              var eventFix = event;
              var today = new Date();
              var endPeriodTime = new Date(event.endPeriodTime);
              if((endPeriodTime - today) > 0)
              {
                eventFix["activity"] = ['active'];
              }
              else{
                eventFix["activity"] = ['finished'];
              }
              eventFix["imageUrl"] =  res;
              myThis.setState({
                event : eventFix
                });
            });
            return true
          }
          return true
        })
        if(myThis.state.status != 'validEvent'){
          this.setState({
            status : "unvalidEvent"
          })
        }
        
        return true})//just for Warrnings
        })

  
       }
     
    render() {
      return (
        <div className="landingPage">
          {
            (this.state.status) 
            ? 
                (this.state.status == 'validEvent') 
                ? 
                <div className="landingPageEvent">
                {
                  (this.state.event.activity == ["active"].toString())
                  ?
                  <EventPage {...this.state} sendToLandingPage={this.sendFromEventPage} />
                  :
                     (this.state.event.activity == ["finished"].toString())
                     ?
                     <FinishedEvent {...this.state} />
                     :
                     ''
                }
                </div> 
                : 
                  (this.state.status == 'unvalidEvent' ) 
                  ? 
                  <GoHome /> : ''
            :
            <Thinking className="thinking"/>
          }
          {/* <LandingPageHeader/> */}
        </div>
      );
    }
  }
  
  export default LandingPage;