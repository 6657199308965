import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import AdminPage from './components/AdminPage';
import { BrowserRouter as Router, Route ,Switch } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import AdminAddEvent from './components/AdminAddEvent';
import 'antd/dist/antd.css';

class App extends Component {
    constructor(props){
        super(props)
        this.state = {}
     }

     
    render() {
      
      return (
        <div className="appBody">
        <Router>
          <Switch>
            <Route path="/admin/add-event" component={AdminAddEvent} />
            <Route path="/admin" component={AdminPage} />
            <Route path="/:uid" component={LandingPage} />
            <Route path="" component={LandingPage} />
          </Switch>
        </Router>
        </div>
      );
    }
  }
  


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
