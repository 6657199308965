import { Select ,Input, message, Button, Checkbox  } from 'antd';
import React, { Component } from 'react';
import firebaseApp from '../firebaseConfig';
import encodeForFireBase from '../Functions/encodeForFireBase.js'
require('firebase/auth');
require('firebase/database');


const { Option } = Select;
class UnPicked extends Component {
    constructor(props){
        super(props)
        this.state = {
            workers : '',
            selfWorker : '',
            title : '',
            comment : '',
        }
        this.save = this.save.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.printHello = this.printHello.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
        this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
     }

    handleOnSelect (roomateId) {
      this.setState({
        roomateId : roomateId
      })
    }

    componentWillMount() {
      const id = this.props.idNumber;
      const selfWorker = this.props.workers.filter((worker) => {
        return worker.id == id
      })[0];
      const workersFiltered = this.props.workers.filter((worker) => {
        return (worker.id != this.props.idNumber && worker.roomType == selfWorker.roomType)
      });
      this.setState({
        selfWorker: selfWorker,
        workers : workersFiltered
      })
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
    }

    handlePhoneInputChange(val){
      const phone = val.target.value;
      const phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //valid phone number regex.
      if(phone.match(phoneno)){
        this.setState({
          phone : phone
        })
      }else{
        this.setState({
          phone : ''
        })
        message.error(`invalid phone number!`);
      }
    }

    handleEmailInputChange(val){
      const email = val.target.value;
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) //valid email regex
      {
        this.setState({
          email : email
        })
      }else{
        this.setState({
          email : ''
        })
      }
    }

    printHello() {
      return (<h2 className="heyWorker">Hey {this.state.selfWorker.firstName + " " + this.state.selfWorker.lastName}! </h2>)
    }

    handleKeyDown(e){
      if (e.key == 'Enter') {
          this.save();
        }
  }
    
    
    save() {
      if(!this.state.phone){
        message.error('valid phone number required!')
        return
      }
      if(!this.state.email){
        message.error('valid email required!')
        return
      }
      if(!this.state.title){
        message.error('You must choose your title!')
        return
      }
      if(!this.state.roomateId){
        message.error('You must choose your roommate!')
        return
      }
      if(this.state.comment.length > 120){
        message.error('You comment is too long!')
        return
      }
      
      const workersFiltered = this.props.workers.filter((worker) => {
        return (worker.id != this.props.idNumber && worker.id !== this.state.roomateId)
    });
      const employee1 = this.props.workers.filter((worker) => {
        return worker.id == this.props.idNumber
    })[0];
      const employee2 = this.props.workers.filter((worker) => {
        return worker.id == this.state.roomateId
    })[0];

      let matchJson = {
        employee1_fname : employee1.firstName ,
        employee1_lname : employee1.lastName ,
        employee1_id : employee1.id,
        employee1_title : this.state.title ,
        employee1_email : this.state.email ,
        employee1_phone : this.state.phone ,
        employee2_fname : employee2.firstName ,
        employee2_lname : employee2.lastName ,
        employee2_id : employee2.id ,
        employee2_title : '',
        employee2_phone : '',
        employee2_email : '',
        matchComment : this.state.comment , 
        roomType : employee1.roomType,
      }
        //insert event data to firbase database-realtime
        firebaseApp.database().ref(encodeForFireBase(this.props.companyName)).child(encodeForFireBase(this.props.eventName)).child('matches/'+employee1.id)
        .set(matchJson)
        .then((res) => {
          // (flag) ? window.location = '/admin/' : flag = true
          //console.log("success")
        }); 
        firebaseApp.database().ref(encodeForFireBase(this.props.companyName)).child(encodeForFireBase(this.props.eventName)).child('workers')
        .set(workersFiltered)
        .then((res) => {
          this.props.sendData(employee1, employee2); //call parent
        }); 

    }

    onChangeTitle(e) {
      const title = e.target.name;
      if(title == "mr"){
        this.setState({
          checkMr : true,
          checkMrs : false,
          checkOther : false,
          title : title
        })
      }
      if(title == "mrs"){
        this.setState({
          checkMr : false,
          checkMrs : true,
          checkOther : false,
          title : title
        })
      }
      if(title == "other"){
        this.setState({
          checkMr : false,
          checkMrs : false,
          checkOther : true,
          title : title
        })
      }
    }
  

  render() {
    
    //   const { selectedItems } = this.state;
    
    // const filteredOptions = this.props.workers.filter((worker) => {
    //     return worker.id !== this.props.idNumber
    // });
    return (
    <div>
  <br/>
  {this.printHello()}
  <h2 className="pleaseChoose">Please choose your roomate:</h2>
  <div className="chooseRoomate">
  <Select
    showSearch
    placeholder = "Choose roomate "
    optionFilterProp = "children"
    className = "workerSelctor"
    onSelect = {this.handleOnSelect}
    onKeyDown = {this.handleKeyDown}
    filterOption = {(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
  {this.state.workers.map((worker,idx) => {
      return <Option value={worker.id} key={idx}>{worker.firstName + " " + worker.lastName}</Option>
  })}
  </Select>
  <br/>
  </div>
  <h2 className="fillDetails">Please fill your details:</h2>
  <div className="titleCheckboxs">
  <Checkbox onChange={this.onChangeTitle} name="mr" checked={this.state.checkMr}>Mr.</Checkbox>
  <Checkbox onChange={this.onChangeTitle} name="mrs" checked={this.state.checkMrs}>Mrs.</Checkbox>
  <Checkbox onChange={this.onChangeTitle} name="other" checked={this.state.checkOther}>other</Checkbox>
  </div>
  <div className="divInputs">
  <Input className="workerInputs"  placeholder="Enter your email" required={true} onChange={this.handleEmailInputChange} name="email" onKeyDown={this.handleKeyDown} />
  </div>
  <div className="divInputs">
  <Input className="workerInputs" placeholder="Enter your phone" required={true} onChange={this.handlePhoneInputChange} name="phone" onKeyDown={this.handleKeyDown} />
  </div>
  <div className="divInputs">
  <Input className="workerInputs" placeholder="Write a comment"  onChange={this.handleInputChange} name="comment" onKeyDown={this.handleKeyDown}/>
  </div>


  <Button className="workerSave" onClick={this.save}>Save</Button>
  
  </div>
    );
  }
}
export default UnPicked//handleInputChange