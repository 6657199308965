import React, { Component } from 'react';
import '../style/AdminAddEvent.css'
import AdminHeader from './AdminHeader';
import { Form,Button,Input,DatePicker,Icon,Upload,message } from 'antd';
import firebaseApp from '../firebaseConfig';
import { ExcelRenderer } from 'react-excel-renderer';
import { Employee } from '../models/Employee.js'
import { Room } from '../models/Room.js'
import encodeForFireBase from '../Functions/encodeForFireBase.js'
require('firebase/auth');
require('firebase/database');
const uuid = require('uuid/v1');

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

// for the upload image section
function beforeUpload(file) {
    const isJpgOrPng = file.type == 'image/jpeg' || file.type == 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Image must smaller than 5MB!');
    }
    return isJpgOrPng && isLt2M;
}

class AdminAddEvent extends Component {
    constructor(props){
        super(props)
        this.state = {
          eventName:"",
          companyName:"",
          endPeriodTime:"",
          loadingBtn : false,
          excelError : false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.create = this.create.bind(this);
     }
     
      //for the upload image section 
      handleChange = info => {
        if (info.file.status == 'uploading') {
          this.setState({ loading: true });
          return;
        }
        if (info.file.status == 'done') {
          // Get this url from response in real world.
            this.setState({
              companyLogo: info.file.originFileObj,
              loading: false
            });
            getBase64(info.file.originFileObj, imageUrl =>
              this.setState({
                imageUrl,
                loading: false,
              }));
        }
      };

      handleInputChange(event) {
          if(!!event)
          {
              //if it's not dateTame picker
              if(!event.date){
                  const target = event.target;
                  const value = target.value;
                  const name = target.name;
                  this.setState({
                    [name]: value
                  });
              }
              //if it is DateTime picker
              else{ 
                  this.setState({
                    endPeriodTime: event.toDate().toString()
                  })
              }
          }
        }


        
    onDragExcelDictionary = (file) => {
      ExcelRenderer(file, (err, resp) => {//TODO
        if(err){
          console.log(err);            
        }
        else{
          var dictionary = [];
          resp.rows.forEach(element => {
            if(!!element[2] && !this.state.excelError){
              message.error('invalid dictionary file!')
              this.setState({
                excelError : true
              })
            }
            dictionary.push(new Room(element[0],element[1]))
          })
          this.setState({
            dictionary: dictionary
          })
        }
      });  
    }

    onDragExcelEmployee = (file) => {
      ExcelRenderer(file, (err, resp) => {
        if(err){
          console.log(err);            
        }
        else{
          var workers = [];
          resp.rows.forEach(element => {
            //unvalid employee excel file (doesnt have 4 column)
            if(!(!!element[0] && !!element[1] && !!element[2] && !!element[3])){
              if(!this.state.excelError){
                message.error('invalid employee excel file!')
                this.setState({
                  excelError : true
                })
              }
              return;
            }else{
              var id = '' + element[0].toString()
              if(id.length == 8)
              {
                id = '0' + id;
              }
              workers.push(new Employee(id,element[1],element[2],element[3]))
            }
          })
          this.setState({
            workers: workers
          })
        }
      });  
    }

    // encodeForFireBase = (code) => {
    //   return code
    //         .replace("_", "-")
    //         .replace(".", "-")
    //         .replace(".", "-")
    //         .replace(".", "-")
    //         .replace(".", "-")
    //         .replace(".", "-")
    //         .replace("$", "-")
    //         .replace("#", "-")
    //         .replace("[", "-")
    //         .replace("]", "-")
    //         .replace("/", "-")
    //         .replace("/", "-")
    //         .replace("/", "-")
    //         .replace("/", "-")
    //         .replace("\\", "-")
    //         .replace("\\", "-")
    //         .replace("\\", "-")
    //         ;
    // }

    mySubmit = () => {
      var flag = false;
      var uid = uuid().split("-")[0]
      

      if(this.state.companyLogo && this.state.companyName && this.state.eventName
         && this.state.endPeriodTime && this.state.workers){
          this.setState({
            loadingBtn: true
          })
          let eventJson = {
            eventName : this.state.eventName ,
            companyName : this.state.companyName ,
            endPeriodTime : this.state.endPeriodTime , 
            workers : this.state.workers,
            eventUid : uid,
            timeStamp : new Date().getTime(),
            dictionary : this.state.dictionary,
          }
        //insert event data to firbase database-realtime
        firebaseApp.database().ref(encodeForFireBase(this.state.companyName)).child(encodeForFireBase(this.state.eventName)).set(eventJson)
        .then((res) => {
          (flag) ? window.location = '/admin/' : flag = true

        }); 

        //insert company logo image to firebase storage
        firebaseApp.storage().ref("Companies-logo").child(encodeForFireBase(this.state.companyName)).put(this.state.companyLogo)
        .then((res) => {
          (flag) ? window.location = '/admin/' : flag = true
        });

       
      }
      else{
        message.error("someting missing!")
      }
    }
     create() {
       debugger
      }
  
      
    
     render() {
        const uploadButton = (
            <div className={"upload-logo"}>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">
              Upload<br/>
              Logo
              </div>
            </div>
          );
        const { imageUrl } = this.state;
        const myThis = this;

        const props = {
            name: 'file',
            multiple: false,
            action: '//jsonplaceholder.typicode.com/posts/',
            onChange(info) {
              const status = info.file.status;
              if (status != 'uploading') {
                // console.log(info.file, info.fileList);
                
              }
              if (status == 'done' && !myThis.state.excelError) {
                message.success(`${info.file.name} file uploaded successfully.`);
                console.log(info.file);
                
                
              } else if (status == 'error') {
                message.error(`${info.file.name} file upload failed.`);
            
             }
           },
         };
        
     const formItemLayout = {
     labelCol: { span: 6 },
     wrapperCol: { span: 14 },
      };

      return (
        <div className="Admin-add-event">
        <AdminHeader/>
        <div className="Add-event-body">
        <h1>Create new event</h1>    {/* Reminder: why ... */}
        <Form>
          <Form.Item label="event name" {...formItemLayout} required={true} >
            <Input placeholder="event name"  onChange={this.handleInputChange} name="eventName"/>
          </Form.Item>
          <Form.Item label="company name" {...formItemLayout} required={true}>
            <Input placeholder="company name"  onChange={this.handleInputChange} name="companyName" />
          </Form.Item>
          
        <div className={"dateAndLogo"}>
        
        <Form.Item className={"draggersImage"} required={true}>
          <Upload
          name="avatar"
           listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          
          onChange={this.handleChange}
          >
          {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
         </Form.Item>
         <Form.Item className={"datePicker"} required={true}  >
            <DatePicker placeholder="Register Deadline" size={"large"} showTime format="YYYY-MM-DD" onChange={this.handleInputChange} name="dateTime"/>
        </Form.Item>
        </div>
        <div className="dropbox">
        <Form.Item label="" className="draggersExcel" required={true}>
              <Upload.Dragger beforeUpload={this.onDragExcelDictionary} {...props} name='file'>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag dictionary excel</p>
                <p className="ant-upload-hint"><b>Dictionary Excel File</b></p>
              </Upload.Dragger>
        </Form.Item>
        <Form.Item label="" className="draggersExcel" required={true}>
              <Upload.Dragger beforeUpload={this.onDragExcelEmployee} {...props} name='file'>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag employee excel</p>
                <p className="ant-upload-hint"><b>Employee Excel File</b></p>
              </Upload.Dragger>
        </Form.Item>
        </div>
      
        <div className="BtnContainer" >
        <Form.Item className="submitItem">
          <Button className="submitBtn" type="primary" onClick={this.mySubmit} loading={this.state.loadingBtn}>
            <b>Create</b>
          </Button>
        </Form.Item>
        </div>
        </Form>
        </div>
        </div>
      );
    }
  }
  
  export default AdminAddEvent;