
function encodeForFireBase(code) {
        return code
              .replace("_", "-")
              .replace(".", "-")
              .replace(".", "-")
              .replace(".", "-")
              .replace(".", "-")
              .replace(".", "-")
              .replace("$", "-")
              .replace("#", "-")
              .replace("[", "-")
              .replace("]", "-")
              .replace("/", "-")
              .replace("/", "-")
              .replace("/", "-")
              .replace("/", "-")
              .replace("\\", "-")
              .replace("\\", "-")
              .replace("\\", "-")
              ;
}
export default encodeForFireBase;