import encodeForFireBase from '../Functions/encodeForFireBase.js'


export function Employee(id,firstName,lastName,roomType) {
    const firstNameCapitalized = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const lastNameCapitalized = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    this.id = id;
    this.firstName = encodeForFireBase(firstNameCapitalized);
    this.lastName = encodeForFireBase(lastNameCapitalized);
    this.roomType = roomType;
  }