import React, { Component } from 'react';
import '../style/ThankYou.css'

class ThankYou extends Component {
    constructor(props){
        super(props)
        this.state = {}
     }

    render() {
      return (
        <div className={"thankYouPage"}>
            <br/>
            <span>Your details has been saved</span>
            <br/>
            <span className="enjoy">Enjoy your vacation</span>
        </div>
      );
    }
  }
  
  export default ThankYou;