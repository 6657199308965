import React, { Component } from 'react';
import {Button, Input, message, Checkbox} from 'antd';
import UnPicked from './UnPicked'
import ThankYou from './ThankYou'
import firebaseApp from '../firebaseConfig';
import { Employee } from '../models/Employee';
import encodeForFireBase from '../Functions/encodeForFireBase.js'
require('firebase/auth');
require('firebase/database');

         
class EventPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            enterBtnType : '',
            idNumber : '',
            userSelctorFlag : false,
            thankYou : false,
            enterBtnDisabled : true,
            comment : ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.enter = this.enter.bind(this);
        this.getData = this.getData.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.cencelPick = this.cencelPick.bind(this);
        this.click = this.click.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
        this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.save = this.save.bind(this);
        
     }//Reminder : why props ?

     
     handleInputChange(val) {
        const id = val.target.value;
        if(!isNaN(id) && id.length === 9){
          this.setState({
            idNumber : id,
            enterBtnType: 'primary',
            enterBtnDisabled : false
          })
        }
        else if(this.state.enterBtnType === 'primary'){
            this.setState({
                enterBtnType: '',
                enterBtnDisabled : true
              })
        }
    }

    handleCommentChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
      }

    handleKeyDown(e){
        if (e.key === 'Enter') {
            this.enter();
          }
    }

    click() {
        debugger
    }

    cencelPick(){
        firebaseApp.database().ref(encodeForFireBase(this.props.event.companyName)).child(encodeForFireBase(this.props.event.eventName)).child('matches/'+this.state.allreadyPicked.employee1_id).remove()
        .then((res) => {
        }); 
        
        var pickedEmployee = this.state.allreadyPicked;
        var employee1 = new Employee(pickedEmployee.employee1_id,pickedEmployee.employee1_fname,pickedEmployee.employee1_lname,pickedEmployee.roomType);
        var employee2 = new Employee(pickedEmployee.employee2_id,pickedEmployee.employee2_fname,pickedEmployee.employee2_lname,pickedEmployee.roomType);
        
        if(!this.props.event.workers){
            this.props.event.workers = [];
        }
        var updateWorkers = this.props.event.workers;
        updateWorkers.push(employee1);
        updateWorkers.push(employee2);

        firebaseApp.database().ref(encodeForFireBase(this.props.event.companyName)).child(encodeForFireBase(this.props.event.eventName)).child('workers')
        .set(updateWorkers)
        .then((res) => {
            this.enter();
          //this.props.sendData(); //call parent
        });
    }


    onChangeTitle(e) {
        const title = e.target.name;
        if(title == "mr"){
          this.setState({
            checkMr : true,
            checkMrs : false,
            checkOther : false,
            title : title
          })
        }
        if(title == "mrs"){
          this.setState({
            checkMr : false,
            checkMrs : true,
            checkOther : false,
            title : title
          })
        }
        if(title == "other"){
          this.setState({
            checkMr : false,
            checkMrs : false,
            checkOther : true,
            title : title
          })
        }
      }
    







    handlePhoneInputChange(val){
        const phone = val.target.value;
        const phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //valid phone number regex.
        if(phone.match(phoneno)){
          this.setState({
            phone : phone
          })
        }else{
          this.setState({
            phone : ''
          })
          message.error(`invalid phone number!`);
        }
      }
  
      handleEmailInputChange(val){
        const email = val.target.value;
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) //valid email regex
        {
          this.setState({
            email : email
          })
        }else{
          this.setState({
            email : ''
          })
        }
      }
  
  
      
      
      save() {
        if(!this.state.phone){
          message.error('valid phone number required!')
          return
        }
        if(!this.state.email){
          message.error('valid email required!')
          return
        }
        if(!this.state.title){
          message.error('You must choose your title!')
          return
        }
        if(this.state.comment.length > 120){
          message.error('You comment is too long!')
          return
        }
        
        const { allreadyPicked, title, email, phone} = this.state;
        let matchJson = {
          employee1_fname : allreadyPicked.employee1_fname ,
          employee1_lname : allreadyPicked.employee1_lname ,
          employee1_id : allreadyPicked.employee1_id,
          employee1_title : allreadyPicked.employee1_title ,
          employee1_email : allreadyPicked.employee1_email ,
          employee1_phone : allreadyPicked.employee1_phone ,
          employee2_fname : allreadyPicked.employee2_fname ,
          employee2_lname : allreadyPicked.employee2_lname ,
          employee2_id : allreadyPicked.employee2_id ,
          employee2_title : title,
          employee2_email : email,
          employee2_phone : phone,
          matchComment : allreadyPicked.matchComment + " | " + this.state.comment , 
          roomType : allreadyPicked.roomType,
        }
          //insert event data to firbase database-realtime
          firebaseApp.database().ref(encodeForFireBase(this.props.event.companyName)).child(encodeForFireBase(this.props.event.eventName)).child('matches/'+allreadyPicked.employee1_id)
          .set(matchJson)
          .then((res) => {
            // (flag) ? window.location = '/admin/' : flag = true
            //console.log("success")
             this.getData()
          }); 
      }










    

    //came from UnPicked component (this 2 picked each other)
    getData(employee1, employee2) {
        this.setState({
            thankYou: true,
            allreadyPicked: false,
            userSelctorFlag: true,
            pickedEmployee1: employee1,
            pickedEmployee2: employee2
        })
    }

    enter () {
        if(this.state.idNumber.toString().length === 9)
        {
            var selfWorker = '';
            if(this.props.event.workers)
            {
                selfWorker = this.props.event.workers.filter((worker) => {
                    return worker.id === this.state.idNumber
                })[0];
                if(!!selfWorker)
                {
                    this.setState({
                        userSelctorFlag : true
                    })
                }
            }
            if(!selfWorker){
                var pickedWorker = ''
                if(!!this.props.event.matches)
                {
                    pickedWorker = Object.values(this.props.event.matches).filter((match) => {
                    return (match.employee1_id == this.state.idNumber || match.employee2_id == this.state.idNumber)
                    })[0];
                }
                if(!!pickedWorker) 
                {
                    this.setState({
                        allreadyPicked : pickedWorker,
                        pickedBySomeoneElse: false,
                    })
                    message.success('you are allready been picked: '+pickedWorker.employee1_fname + " " +pickedWorker.employee1_lname +' with ' +pickedWorker.employee2_fname + " " + pickedWorker.employee2_lname) 
                    if(this.state.idNumber == pickedWorker.employee2_id && !pickedWorker.employee2_email && !pickedWorker.employee2_phone){
                        this.setState({
                            pickedBySomeoneElse: true,
                        })
                    }
                }else{
                    message.error('incorrect ID number !');
                }
            }
        }
    }

    render() {
       
      return (
        <div className="eventContainer">
        <div className={"middleBox"}>
          <img src={this.props.event.imageUrl} alt='logo' className={"CompanyLogo"}></img> 
          {/* <Button onClick={this.click}>dd</Button> */}
          <h1 className={"eventName"}>{this.props.event.eventName}</h1>
            {
                (this.state.userSelctorFlag) 
                ? 
                    (!this.state.thankYou)
                     ?
                    <UnPicked sendData={this.getData} {...this.props.event}  idNumber={this.state.idNumber} />  
                    :
                    // <Alert
                    // showIcon
                    // message="Successfully Saved! "
                    // description= {"Guests: " + this.state.pickedEmployee1.firstName + " " + this.state.pickedEmployee1.lastName + " with "
                    // + this.state.pickedEmployee2.firstName + " " + this.state.pickedEmployee2.lastName + "<br>" }
                    // type="success"
                    // />
                    // <Result
                    // status="success"
                    // title="Successfully Saved!  Enjoy!"
                    // subTitle= {"Guests: " + this.state.pickedEmployee1.firstName + " " + this.state.pickedEmployee1.lastName + " with "
                    // + this.state.pickedEmployee2.firstName + " " + this.state.pickedEmployee2.lastName }
                    // extra={[
                    // <Button type="primary" key="console">
                    //     Go Console
                    // </Button>,
                    // <Button key="buy">Buy Again</Button>,
                    // ]}
                    // />
                    <ThankYou />
                :
                <div className="">
                    {/* <Input type="tel" pattern="[0-9]*" size="large" placeholder="please insert your ID number" className={"idInput"} onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} />
                    {/* <InputNumber  size="large" placeholder="please insert your ID number" className={"idInput"} onChange={this.handleInputChange}  min={100000000} max={999999999} step={1}/> */}
                    {/* <Input type="number" size="large" placeholder="please insert your ID number" className={"idInput"} onChange={this.handleInputChange} /> */}
                    {/* <Button type={this.state.enterBtnType} disabled={this.state.enterBtnDisabled} onClick={this.enter} name="enter" className={"enterBtn"}> Enter </Button> */} 
                    {
                        (!this.state.allreadyPicked) 
                        ?
                        <div>
                            <Input type="id" size="large" placeholder="please insert your ID number" className={"idInput"} onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} />
                            <Button type={this.state.enterBtnType} disabled={this.state.enterBtnDisabled} onClick={this.enter} name="enter" className={"enterBtn"}> Enter </Button>
                        </div>
                        :
                           <div className="cencelAreaPicked">
                           
                             {
                                 (this.state.pickedBySomeoneElse)
                                 ?
                                 <div className="pickedBySomeoneElse">
                                    <div className={"beenPickedText"}>
                                        You have already been picked! 
                                        <br/>
                                        {/* <Button onClick={this.click}>d</Button> */}
                                        <b>
                                        {this.state.allreadyPicked.employee1_fname + " " + this.state.allreadyPicked.employee1_lname + " " }
                                        </b>
                                        with 
                                        <b>
                                        {" " + this.state.allreadyPicked.employee2_fname + " " +this.state.allreadyPicked.employee2_lname }
                                        </b>
                                    </div>
                                    <h2 className="fillDetails">Please fill your details:</h2>
                                    <div className="titleCheckboxs">
                                    <Checkbox onChange={this.onChangeTitle} name="mr" checked={this.state.checkMr}>Mr.</Checkbox>
                                    <Checkbox onChange={this.onChangeTitle} name="mrs" checked={this.state.checkMrs}>Mrs.</Checkbox>
                                    <Checkbox onChange={this.onChangeTitle} name="other" checked={this.state.checkOther}>other</Checkbox>
                                    </div>
                                    <div className="divInputs">
                                    <Input className="workerInputs" id="emailUp" placeholder="Enter your email" required={true} onChange={this.handleEmailInputChange} name="email" onKeyDown={this.handleKeyDown} />
                                    </div>
                                    <div className="divInputs">
                                    <Input className="workerInputs" placeholder="Enter your phone" required={true} onChange={this.handlePhoneInputChange} name="phone" onKeyDown={this.handleKeyDown} />
                                    </div>
                                    <div className="divInputs">
                                    <Input className="workerInputs" placeholder="Write a comment"  onChange={this.handleCommentChange} name="comment" onKeyDown={this.handleKeyDown}/>
                                    </div>
                                    <Button className="workerSave" onClick={this.save}>Save</Button>
                                    <div className="cencelLine">
                                        want to change your roommate? 
                                        <br/>
                                        <Button onClick={() => { if (window.confirm('Are you sure you want to change rommate?')) this.cencelPick()}} type="danger" size="small" icon="usergroup-delete" />  
                                    </div>
                                 </div>
                                 :
                                 <div className="cencelArea">
                                    You have already been picked! 
                                    <br/>
                                    {/* <Button onClick={this.click}>d</Button> */}
                                    <b>
                                    {this.state.allreadyPicked.employee1_fname + " " + this.state.allreadyPicked.employee1_lname + " " }
                                    </b>
                                    with 
                                    <b>
                                    {" " + this.state.allreadyPicked.employee2_fname + " " +this.state.allreadyPicked.employee2_lname }
                                    </b>
                                    <div className="cencelLine">
                                        want to change your roommate? 
                                        <br/>
                                        <Button onClick={() => { if (window.confirm('Are you sure you want to change rommate?')) this.cencelPick()}} type="danger" size="small" icon="usergroup-delete" />
                                    </div>
                                  </div>
                             }
                            <br/>
                           
                            {/* <Icon type="usergroup-delete" /> */}
                            {/* <button onClick={this.cencelPick}>cencel match</button> */}
                            </div>
                       
                       
                    }
                </div>
            }
            {/* <div className="erezLogoContainer">
                <div className="erezLogoBox">
                    <img src={require('../images/erezLogo.png')} className="erezLogo" />
                </div>
            </div> */}

          </div>
          <div className="footer">
            <div className="erezLogo">
              <a href="https://www.erezkanelo.com/">
                <img src={require('../images/erezLogo.png')} className="erezLogo" />
              </a>
            </div>
          </div>
          </div>
      );
    }
  }
  
  export default EventPage;