import React, { Component } from 'react';
import {Button, Result} from 'antd';
import '../style/GoHome.css'

class GoHome extends Component {
    constructor(props){
        super(props)
        this.state = {}
     }
    render() {
      return (
        <div>
         <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary">Back Home</Button>}
         />
        </div>
      );
    }
  }
  
  export default GoHome;