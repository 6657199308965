import React, { Component } from 'react';
import '../style/FinishedEvent.css'

class FinishedEvent extends Component {
    constructor(props){
        super(props)
        this.state = {}
     }
    render() {
      return (
        <div className={"middleBox"}>
          <img src={this.props.event.imageUrl} alt='logo' className={"CompanyLogo"}></img> 
          {/* <Button onClick={this.click}>dd</Button> */}
          <h1 className={"eventName"}>{this.props.event.eventName}</h1>
        <div className="total">
         {/* <h1 className="thankYou"><b>Thank you!</b></h1> */}
         <h1 className="middle">This event registration has been <b>finished!</b></h1>
         <h1 className="enjoy"><b>Enjoy</b> your vacation!</h1>
        </div>
        </div>
      );
    }
  }
  
  export default FinishedEvent;