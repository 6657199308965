import React, { Component } from 'react';
import { message } from 'antd';
import '../style/Login.css'
import firebaseApp from '../firebaseConfig';

class Login extends Component {
    constructor(props){
        super(props)
        this.state = {}
        this.authWithIdAndPassword = this.authWithIdAndPassword.bind(this);
     }

     authWithIdAndPassword (event) {
        event.preventDefault();
        const email = event.currentTarget.email.value;
        const password = event.currentTarget.password.value;
        firebaseApp.auth().signInWithEmailAndPassword(email,password).catch(function(error) {
        // Handle Errors here.
        var errorMessage = error.message;
        message.error(errorMessage);
        // ...
      }).then((res) => console.log(res));
     }

    render() {
      return (
        <div className='loginContainer'>
        <div className="login-page">
        <div className="form">
                <form className="login-form" onSubmit={(event) => { this.authWithIdAndPassword(event) }} 
                ref={(form) => { this.loginForm = form }}>
                <input name="email" type="email" ref={(input) => { this.emailInput = input }} placeholder="Email"/>
                <input name="password" type="password" ref={(input) => { this.passwordInput = input }} placeholder="Password"/>
                <button type='submit' className='marginBottom'>login</button>
            </form>
        </div>
        </div>
      </div>
      );
    }
  }
  
  export default Login;